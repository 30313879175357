import React from "react";
import scrollTo from "gatsby-plugin-smoothscroll";
import {navigate} from "gatsby";
class Menu extends React.Component {
    state = {
        showMenu: false,
        menuChanged: false,
        hoverCircle: false
    }

    toggleMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu,
            menuChanged: true
        })
    }

    setHover = () => {
        this.setState({
            hoverCircle: true
        })
    }

    unsetHover = () => {
        this.setState({
            hoverCircle: false
        })
    }

    isHome = () => {
        const location = window.location;
        return location.pathname === "/";
    }
    render() {
        const menuActivated = this.state.showMenu && this.state.menuChanged ? 'is-active' : '';
        const menuDeactivated = !this.state.showMenu && this.state.menuChanged ? 'not-active' : '';
        const menuHover = this.state.hoverCircle ? 'is-hover' : '';

        return (
            <>
                <div id={"nav-container"} className={`${menuHover} ${menuActivated} ${menuDeactivated}`}>
                    <nav>
                        <ul>
                            <li><button onClick={async () => {
                                if(this.isHome()) {
                                    scrollTo('#Dienstleistungen');
                                } else {
                                    navigate("/#Dienstleistungen");
                                }
                            }} onMouseUp={this.toggleMenu}>Unsere Treuhand-Dienstleistungen</button></li>
                            <li><button onClick={() => {
                                if(this.isHome()) {
                                    scrollTo('#UeberUns');
                                } else {
                                    navigate("/#UeberUns");
                                }
                            }} onMouseUp={this.toggleMenu}>Über uns</button></li>
                            <li><button onClick={() => {
                                if(this.isHome()) {
                                    scrollTo('#Kontakt');
                                } else {
                                    navigate("/#Kontakt");
                                }
                            }} onMouseUp={this.toggleMenu}>Kontakt</button></li>
                        </ul>
                    </nav>
                </div>
                <div
                    className={`hamburger hamburger--slider ${menuActivated}`}
                    onClick={this.toggleMenu}
                    onKeyDown={(e) => { if(e.code === 'Enter' || e.code === 'Space') {
                        this.toggleMenu()
                    }}}
                    onMouseOver={this.setHover}
                    onMouseOut={this.unsetHover}
                    onFocus={this.setHover}
                    onBlur={this.unsetHover}
                    tabIndex="0"
                    aria-label="Menu"
                    role="button"
                    aria-controls="navigation"
                >
                    <div className="hamburger-box">
                        <div className="hamburger-inner">
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Menu
