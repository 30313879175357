import * as React from "react"
import PropTypes from "prop-types"
import Logo from "../../images/logo.svg";
import Menu from "../elements/menu"

const Header = ({ siteTitle }) => (
  <header>
    <Logo id={"logo"} />
    <Menu />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
